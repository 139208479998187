import { AllHTMLAttributes, FC, useState } from "react";
import joinClassNames from "classnames";

import EmptyCircledIcon from "icons/empty-circled.svg?react";

import { BaseProps } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props extends AllHTMLAttributes<HTMLImageElement>, BaseProps {}

const Icon: FC<Props> = ({
  src,
  altImage: DefaultImage = EmptyCircledIcon,
  className,
  ...props
}) => {
  const [isError, setIsError] = useState(false);
  const imageClasses = joinClassNames(classes.icon, className);
  if (isError || !src) {
    return (
      <DefaultImage className={imageClasses} data-size={props["data-size"]} />
    );
  }

  return (
    <img
      onError={() => {
        setIsError(true);
      }}
      className={imageClasses}
      src={src}
      {...props}
    />
  );
};

export default Icon;
